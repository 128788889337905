import { openDB, type IDBPDatabase } from "idb";
import type { Subtitle, SubtitleDB } from "~/types/subtitleDB";
import { type NodeList, type NodeCue } from "~/types/node";

export const useSubtitleStore = () => {
  let db: IDBPDatabase<SubtitleDB> | null = null;

  const initDB = async () => {
    if (!db) {
      db = await openDB<SubtitleDB>("SubtitleDatabase", 1, {
        upgrade(db) {
          db.createObjectStore("subtitles", { keyPath: "id" });
        },
      });
    }
  };

  const saveSubtitle = async (subtitle: Subtitle) => {
    await initDB();
    if (db) {
      if (Array.isArray(subtitle.data)) {
        const fullCueData = subtitle.data.map((s: NodeCue) => ({
          ...s,
          data: getFullCueData(s.data),
        }));

        await db.put("subtitles", {
          ...subtitle,
          data: JSON.stringify(subtitle.data),
        });
      } else {
        await db.put("subtitles", subtitle);
      }
    }
  };

  const getSubtitle = async (id: string): Promise<Subtitle | undefined> => {
    await initDB();
    if (db) {
      return await db.get("subtitles", id);
    }
  };

  const deleteSubtitle = async (id: string) => {
    await initDB();
    if (db) {
      await db.delete("subtitles", id);
    }
  };

  const getAllSubtitles = async (): Promise<Subtitle[]> => {
    await initDB();
    if (db) {
      return await db.getAll("subtitles");
    }
    return [];
  };

  const getFullCueData = (cue: VTTCue) => {
    return {
      ...cue,
      id: cue.id,
      startTime: cue.startTime,
      endTime: cue.endTime,
      text: cue.text,
    };
  };
  return {
    saveSubtitle,
    getSubtitle,
    deleteSubtitle,
    getAllSubtitles,
  };
};
